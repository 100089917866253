import React from "react";
import Page from "../components/Page";
import Layout from "../components/Layout";
import StoryblokService from "../utils/storyblok-service";
import { PageContext } from 'src/utils/context'

export default class Editor extends React.Component {
  state = {
    story: null
  }
 
  async getInitialStory() {
    const urlParams = new URLSearchParams(window.location.search);
    const path = urlParams.get('path')    
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${path}`, {version: 'draft'})
    return story
  }
 
  async componentDidMount() {
    let story = await this.getInitialStory()
    if(story.content) this.setState({ story })
    setTimeout(() => StoryblokService.initEditor(this), 200)
  }
 
  render() {
    if(this.state.story === null) {
      return (<div>Loading</div>)
    }
    return (
      <PageContext.Provider
        value={{
          hasHero:
            this.state.story.content.body &&
            this.state.story.content.body[0]?.component === "hero"
              ? true
              : false,
          currentPage: this.state.story.uuid,
          location: this.props.location,
          component: this.state.story.content.component,
          tag_list: this.state.story.tag_list,
          first_published_at: this.state.story.first_published_at,
          name: this.state.story.name,
          full_slug: this.state.story.full_slug,
          featured_image: this.state.story.content.featured_image
        }}
      >
        <Layout location={this.props.location} isEditor={true}>
          <Page blok={this.state.story.content} />
        </Layout>
      </PageContext.Provider>
    )
  }
}